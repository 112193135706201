import { Suspense, lazy } from "react";
// import { StrictMode } from 'react';
import { createRoot } from "react-dom/client";
import "./index.css";
// import reportWebVitals from './reportWebVitals';
import Splash from "./components/Splash";

const rootElement = document.getElementById("root");
if (!rootElement) {
	throw new Error("Erro ao encontrar elemento root no index.html");
}
const originalConsoleError = console.error;

console.error = (...args) => {
	if (
		args[0] && args[0].includes && 
		(args[0]?.includes("Accessing element.ref was removed in React 19") ||
			args[0]?.includes("[antd: compatible] antd v5 support React is 16 ~ 18"))
	) {
		return; // TODO: remover função e variavel, após atualização do antd
	}
	originalConsoleError(...args);
};
const originalConsoleInfo = console.info;

console.info = (...args) => {
	if (args[0] && args[0].includes && args[0]?.includes("[HMR]")) {
		return; // TODO: remover função e variavel, após atualização do antd
	}
	originalConsoleInfo(...args);
};

const root = createRoot(rootElement);

const App = lazy(() => import("./App"));

root.render(
	// <StrictMode> {/* Retirar no build, faz com que hooks executem duas vezes pra garantir consistência no layout e adiciona mais avisos */}

	<Suspense fallback={<Splash className={"Splash"} />}>
		<Splash className={"SplashFade"} />
		<App />
	</Suspense>
	// </StrictMode>
);

// reportWebVitals();
// Adicionar caso no futuro seja necessário analisar a performance do frontend, adicionar "web-vitals": "^2.1.4" no package.json
